<template lang="pug">
include ../../configs/templatesPUG/template.pug

div
  div(v-if="validateType")
    v-menu(
      ref="menu"
      :close-on-content-click="false"
      content-class="rounded-lg date-picker-menu mt-n4"
      offset-y
      @input="resetData").date-picker-menu
      template(#activator="{ on }")
        div(v-on="on").custom-select
          label(v-if="label") {{ $t(label) }}
            span(v-if="label").blue--text.ml-1 {{ validateType.includes('required') ? '*' : '' }}
          v-text-field(
            v-model="formattedDate"
            :placeholder="$t(placeholder)"
            :error-messages="errorMessage"
            outlined
            dense
            solo
            readonly)
            template(#append)
              v-img(src="@/assets/images/calendar.svg" alt="calendar" width="20" height="20").cursor-pointer
      div.date-picker-wrapper.pt-5
        div.d-flex.justify-space-around.mb-5
          div.fs-16 {{ $t('selectTheIssueDate') }}
          v-icon(@click="$refs.menu.isActive = false").cursor-pointer mdi-close
        div.d-flex.w-100.justify-center
          div.width-250
            div.d-flex.justify-space-between
              div(@click="changeCalendarType('MONTH')").cursor-pointer.text-capitalize {{ currentMonth }}
              div(@click="changeCalendarType('YEAR')").cursor-pointer.color-blue {{ currentYear }}
            v-divider.width-250.mt-1
        v-date-picker(
          v-model="currentDate"
          :active-picker="calendarType"
          :locale="localeAbbr"
          :weekday-format="weekdayFormat"
          no-title
          scrollable
          @update:picker-date="scrollDate"
          @click:year="calendarType = 'DATE'"
          @click:month="calendarType = 'DATE'"
          @change="value => $emit('input', value)")
          template(#default)
            div.w-100
              div.d-flex.justify-center
                +btn-outlined('#026B81', 'btn.reset')(outlined @click="resetCalendar").mr-1
                +btn('#026B81', 'btn.apply')(@click="$refs.menu.isActive = false").ml-1
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {
    validateType: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    errorMessage: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      moment,
      calendarType: 'DATE',
      currentDate: '',
      scrollMonth: '',
      scrollYear: ''
    }
  },
  computed: {
    ...mapState({
      lang: state => state.app.lang
    }),
    currentMonth () {
      return this.selectedMonth === this.scrollMonth 
        ? this.selectedMonth 
        : (this.todayMonth === this.scrollMonth ? this.todayMonth : this.scrollMonth) 
    },
    currentYear () {
      return this.selectedYear === this.scrollYear 
        ? this.selectedYear 
        : (moment().year() === this.scrollYear ? moment().year() : this.scrollYear)
    },
    todayMonth () {
      return moment().locale(this.localeAbbr).format('MMMM')
    },
    formattedDate () {
      return this.currentDate ?  this.$moment(this.currentDate).format('DD.MM.YYYY') : ''
    },
    localeAbbr () {
      return this.lang === 'ua' ? 'uk' : this.lang
    },
    selectedYear () {
      return this.currentDate ? moment(this.currentDate).format('YYYY') : ''
    },
    selectedMonth () {
      return this.currentDate ? moment(this.currentDate).locale(this.localeAbbr).format('MMMM') : ''
    },
  },
  methods: {
    resetData () {
      if (!this.currentDate) this.scrollMonth = this.todayMonth
    },

    scrollDate (date) {
      this.scrollMonth = moment(date).locale(this.localeAbbr).format('MMMM')
      this.scrollYear = moment(date).format('YYYY')
    },
    
    weekdayFormat (date) {
      moment.locale(this.localeAbbr)
      return moment.weekdaysMin()[new Date(date).getDay(date)]
    },

    resetCalendar () {
      this.calendarType = 'DATE'
      this.currentDate = ''
    },

    changeCalendarType (type) {
      this.calendarType = type
    }
  }
}
</script>